<script setup lang="ts">
withDefaults(
  defineProps<{
    /**
     * Dark mode.
     */
    darkMode?: boolean | 'auto'
    /**
     * Text which is appended to the end of the input slot
     * e.g. ".padlet.org"
     */
    inputSuffix?: string
    /**
     * Describes the input field
     * e.g. "Minimum of 8 characters"
     */
    inputDescriptor?: string
    /**
     * The input field points to `inputDescriptor` by using `:aria-describedby="${inputDescriptorId}"`.
     * Needs to be matched with `aria-describedby` value of the input field.
     *
     * @see https://web.dev/sign-in-form-best-practices/#accessible-password-inputs
     */
    inputDescriptorId?: string
  }>(),
  {
    darkMode: 'auto',
    inputSuffix: undefined,
    inputDescriptor: undefined,
    inputDescriptorId: undefined,
  },
)
</script>

<script lang="ts">
export default {}
</script>

<template>
  <div class="relative block font-sans">
    <div v-if="$slots.label || $slots.inputAccessory" class="flex justify-between mb-1">
      <!-- @slot Input label -->
      <slot name="label" />

      <!-- @slot Input accessory -->
      <slot name="inputAccessory" />
    </div>

    <div class="flex flex-row flex-wrap items-center">
      <!-- @slot Input field -->
      <slot name="input" />
      <span
        v-if="inputSuffix"
        :class="[
          'px-1',
          'text-body-posts',
          {
            'text-dark-text-100': darkMode === false,
            'text-light-text-100': darkMode === true,
            'text-dark-text-100 dark:text-light-text-100': darkMode === 'auto',
          },
        ]"
      >
        {{ inputSuffix }}
      </span>
    </div>

    <!-- 
      @slot Focused after input accessory gets focused after the input when pressing Tab.
      See AuthOrgLoginApp.vue for the usage.
    -->
    <slot name="focusedAfterInputAccessory" />

    <p
      v-if="inputDescriptor"
      :id="inputDescriptorId"
      :class="[
        'm-0',
        'pt-2',
        'px-1 tablet-portrait:px-4',
        'text-body-extra-small',
        {
          'text-dark-text-200': darkMode === false,
          'text-light-text-200': darkMode === true,
          'text-dark-text-200 dark:text-light-text-200': darkMode === 'auto',
        },
      ]"
    >
      {{ inputDescriptor }}
    </p>
  </div>
</template>
